<!--
 * @Author: zhangjingqing
 * @Date: 2022-02-11 17:02:03
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-16 21:53:52
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\userManage\user\Index.vue
-->
<template>
  <div class="container">
    <div class="options">
      <a-space>
        <a-button type="primary" icon="plus" @click="handleAdd"
          >添加用户</a-button
        ><a-button icon="lock" @click="handleUpdatePsd">修改密码</a-button>
      </a-space>
    </div>
    <a-table
      :columns="columns"
      :data-source="data"
      :row-selection="rowSelection"
      :loading="loading"
      :pagination="{ current: current, pageSize: pageSize, total: total }"
      @change="handleTableChange"
    >
      <a
        slot="realname"
        slot-scope="realname, record"
        @click="handleShow(record)"
      >
        {{ realname }}
      </a>
      <span slot="agentRole" slot-scope="agentRole">
        {{
          roleList.filter((item) => item.id == agentRole)[0]
            ? roleList.filter((item) => item.id == agentRole)[0].name
            : "-"
        }}
      </span>
      <span slot="status" slot-scope="status">
        <a-tag :key="status" :color="status == '1' ? 'green' : 'red'">
          {{ statusFormatter(status) }}
        </a-tag>
      </span>
      <span slot="createTime" slot-scope="createTime">
        {{ $moment(createTime).format("YYYY-MM-DD") }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click="handleEdit(record)">编辑</a>
        <a-divider type="vertical" />
        <a @click="handleDelete(record.id)">删除</a>
        <a-divider type="vertical" />
        <a-dropdown>
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            修改状态<a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a href="javascript:;" @click="handleStatusChange(record.id, 1)"
                >启用</a
              >
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="handleStatusChange(record.id, 2)"
                >禁用</a
              >
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>
    <user-modal ref="userModal" @reload="queryList" />
    <password-modal ref="passwordModal"></password-modal>
  </div>
</template>
<script>
import UserModal from "./components/UserModal";
import PasswordModal from "./components/PasswordModal";
import {
  querySystemUserList,
  updateStatus,
  deleteSystemUser,
} from "@/api/user";
import { querySystemRoleList } from "@/api/role";
export default {
  name: "user",
  data() {
    return {
      loading: false,
      data: [],
      columns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "姓名",
          dataIndex: "realname",
          key: "realname",
          scopedSlots: { customRender: "realname" },
        },
        {
          title: "账号",
          dataIndex: "email",
          key: "email",
        },
        {
          title: "手机号",
          dataIndex: "mobile",
          key: "mobile",
        },
        {
          title: "角色",
          dataIndex: "agentRole",
          key: "agentRole",
          scopedSlots: { customRender: "agentRole" },
        },
        // {
        //   title: "岗位",
        //   dataIndex: "positionId",
        //   key: "positionId",
        //   customRender: function (text) {
        //     if (text == 100) {
        //       return "经理";
        //     }
        //   },
        // },
        {
          title: "租户id",
          dataIndex: "tenantId",
          key: "tenantId",
        },
        {
          title: "状态",
          key: "status",
          dataIndex: "status",
          align: "center",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          key: "createTime",
          align: "center",
          scopedSlots: { customRender: "createTime" },
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      current: 1,
      pageSize: 10,
      total: 0,
      roleList: [],
      selectedRowKeys: [],
      selectedRows: [],
    };
  },
  components: { UserModal, PasswordModal },
  async created() {
    await this.queryRoleList();
    await this.queryList();
  },
  methods: {
    async queryRoleList() {
      this.loading = true;
      let res = await querySystemRoleList({
        page: 1,
        pageSize: 100,
      });
      if (res.status == 200) {
        this.roleList = res.data.rows;
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
      this.loading = false;
    },
    statusFormatter(status) {
      if (status == 1) {
        return "启用";
      } else {
        return "禁用";
      }
    },
    async queryList() {
      this.loading = true;
      let res = await querySystemUserList({
        page: this.current,
        pageSize: this.pageSize,
      });
      if (res.status == 200) {
        this.data = res.data.rows;
        this.total = res.data.records;
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
      this.loading = false;
    },
    handleDelete(id) {
      const that = this;
      this.$confirm({
        title: "确认删除",
        content: "是否删除当前数据?",
        onOk: async function () {
          let res = await deleteSystemUser({ id: id });
          if (res.status == 200) {
            that.$message.success("删除成功");
            that.queryList();
          } else {
            that.$notification.error({
              message: "系统提示",
              description: res.msg || res.message,
              duration: 4,
            });
          }
        },
      });
    },
    handleShow(record) {
      this.$refs.userModal.show(1, record);
    },
    handleAdd() {
      this.$refs.userModal.show(2);
    },
    handleEdit(record) {
      this.$refs.userModal.show(3, record);
    },
    async handleStatusChange(id, status) {
      let res = await updateStatus({
        id: id,
        status: status,
      });
      if (res.status == 200) {
        this.queryList();
        this.$message.success("修改成功！");
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
    },
    handleTableChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.total = pagination.total;
      this.queryList();
    },
    handleUpdatePsd() {
      if (this.selectedRows.length != 1) {
        return this.$message.warning("请选择一条数据！");
      }
      this.$refs.passwordModal.show(this.selectedRows[0].email);
    },
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys;
          this.selectedRows = selectedRows;
        },
      };
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-tag {
  margin: 0;
}
.container {
  padding: 20px;
  background: #fff;
  min-height: 280px;
}
.options {
  padding-bottom: 16px;
}
</style>
