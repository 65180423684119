/*
 * @Author: zhangjingqing
 * @Date: 2022-02-14 14:48:54
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-03-06 21:45:35
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\api\role.js
 */
import { getAction, postAction } from "./http/manage";
const URL = process.env.VUE_APP_API_PREFIX;
// 查询角色列表
const querySystemRoleList = (params) =>
  getAction(URL + "/role/querySystemRoleList", params);
// 创建角色
const createSystemRole = (params) =>
  postAction(URL + "/role/createSystemRole", params);
// 编辑角色
const updateSystemRole = (params) =>
  postAction(URL + "/role/updateSystemRole", params);
// 删除角色
const deleteSystemRole = (params) =>
  postAction(URL + "/role/deleteSystemRole?id=" + params.id);
// 根据id查询角色信息
const querySystemRole = (params) =>
  postAction(URL + "/role/querySystemRole?id=" + params.id);

export {
  querySystemRoleList,
  createSystemRole,
  updateSystemRole,
  deleteSystemRole,
  querySystemRole,
};
