<!--
 * @Author: zhangjingqing
 * @Date: 2022-05-30 15:55:16
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-03 22:50:23
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\userManage\user\components\PasswordModal.vue
-->
<template>
  <a-modal
    title="修改密码"
    :width="800"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleSave"
    @cancel="handleCancel"
  >
    <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
      <a-form-item label="旧密码">
        <a-input
          type="password"
          v-decorator="[
            'oldPassword',
            {
              rules: [{ required: true, message: '旧密码不能为空!' }],
            },
          ]"
          placeholder="请输入旧密码"
        />
      </a-form-item>
      <a-form-item label="新密码">
        <a-input
          type="password"
          v-decorator="[
            'newPassword',
            {
              rules: [{ required: true, message: '新密码不能为空!' }],
            },
          ]"
          placeholder="请输入新密码"
        />
      </a-form-item>
      <a-form-item label="确认密码">
        <a-input
          type="password"
          v-decorator="[
            'newPassword2',
            {
              rules: [
                { required: true, message: '确认密码不能为空!' },
                { validator: this.handlePasswordCheck },
              ],
            },
          ]"
          placeholder="请输入确认密码"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { updatePassword } from "@/api/user";
import pick from "lodash.pick";
export default {
  name: "passwordModal",
  data() {
    return {
      visible: false,
      form: this.$form.createForm(this, { name: "form" }),
      email: "",
      confirmLoading: false,
    };
  },
  methods: {
    show(email) {
      this.email = email;
      this.visible = true;
    },
    handleSave() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let params = Object.assign(
            {},
            pick(values, "oldPassword", "newPassword"),
            { email: this.email }
          );
          let res = await updatePassword(params);
          if (res.status == 200) {
            this.$message.success("修改成功！");
            this.handleCancel();
          } else {
            let msg = res.msg || res.message;
            let len = msg.length;
            this.$notification.error({
              message: "系统提示",
              description: len > 100 ? "系统错误，请联系管理员" : msg,
              duration: 4,
            });
          }
        }
      });
    },
    handleCancel() {
      this.form.resetFields();
      this.visible = false;
    },
    handlePasswordCheck(rule, value, callback) {
      let password = this.form.getFieldValue("newPassword");
      if (value === undefined) {
        callback(new Error("请输入密码"));
      }
      if (value && password && value.trim() !== password.trim()) {
        callback(new Error("两次密码不一致"));
      }
      callback();
    },
  },
};
</script>
<style lang="less" scoped>
</style>