var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{attrs:{"title":_vm.title,"placement":"right","width":600,"closable":false,"visible":_vm.visible},on:{"close":_vm.handleClose}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 16 }}},[_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'realname',
          { rules: [{ required: true, message: '姓名不能为空!' }] },
        ]),expression:"[\n          'realname',\n          { rules: [{ required: true, message: '姓名不能为空!' }] },\n        ]"}],attrs:{"placeholder":"请输入姓名","disabled":_vm.status == 1}})],1),_c('a-form-item',{attrs:{"label":"账号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          { rules: [{ required: true, message: '邮箱不能为空!' }] },
        ]),expression:"[\n          'email',\n          { rules: [{ required: true, message: '邮箱不能为空!' }] },\n        ]"}],attrs:{"placeholder":"请输入邮箱","disabled":_vm.status == 1}})],1),(_vm.status == 2)?_c('a-form-item',{attrs:{"label":"密码"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          { rules: [{ required: true, message: '密码不能为空!' }] },
        ]),expression:"[\n          'password',\n          { rules: [{ required: true, message: '密码不能为空!' }] },\n        ]"}],attrs:{"placeholder":"请输入密码","disabled":_vm.status == 1}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"手机号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'mobile',
          { rules: [{ required: true, message: '手机号不能为空!' }] },
        ]),expression:"[\n          'mobile',\n          { rules: [{ required: true, message: '手机号不能为空!' }] },\n        ]"}],attrs:{"placeholder":"请输入手机号","disabled":_vm.status == 1}})],1),_c('a-form-item',{attrs:{"label":"岗位"}},[_c('a-tree-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'positionId',
          { rules: [{ required: true, message: '岗位不能为空!' }] },
        ]),expression:"[\n          'positionId',\n          { rules: [{ required: true, message: '岗位不能为空!' }] },\n        ]"}],attrs:{"blockNode":true,"load-data":_vm.onLoadData,"tree-data":_vm.treeData,"replaceFields":{
          children: 'children',
          title: 'name',
          key: 'id',
          value: 'id',
        },"loadedKeys":_vm.loadedKeys,"placeholder":"请选择岗位","disabled":_vm.status == 1},on:{"load":_vm.onLoad}})],1),_c('a-form-item',{attrs:{"label":"角色"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'agentRole',
          { rules: [{ required: true, message: '角色不能为空!' }] },
        ]),expression:"[\n          'agentRole',\n          { rules: [{ required: true, message: '角色不能为空!' }] },\n        ]"}],attrs:{"placeholder":"请选择角色","disabled":_vm.status == 1}},_vm._l((_vm.roleList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id.toString()}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"用户类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'type',
          { rules: [{ required: true, message: '用户类型不能为空!' }] },
        ]),expression:"[\n          'type',\n          { rules: [{ required: true, message: '用户类型不能为空!' }] },\n        ]"}],attrs:{"placeholder":"请选择类型","disabled":_vm.status == 1}},[_c('a-select-option',{attrs:{"value":1}},[_vm._v(" 超级管理员 ")]),_c('a-select-option',{attrs:{"value":2}},[_vm._v(" 管理员 ")]),_c('a-select-option',{attrs:{"value":3}},[_vm._v(" 普通 ")])],1)],1)],1),_c('div',{style:({
      position: 'absolute',
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e8e8e8',
      padding: '10px 16px',
      textAlign: 'right',
      left: 0,
      background: '#fff',
      borderRadius: '0 0 4px 4px',
    })},[_c('a-button',{staticStyle:{"margin-right":"8px"},on:{"click":_vm.handleClose}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSave}},[_vm._v("确定")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }