var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"修改密码","width":800,"visible":_vm.visible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleSave,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 4 },"wrapper-col":{ span: 16 }}},[_c('a-form-item',{attrs:{"label":"旧密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'oldPassword',
          {
            rules: [{ required: true, message: '旧密码不能为空!' }],
          },
        ]),expression:"[\n          'oldPassword',\n          {\n            rules: [{ required: true, message: '旧密码不能为空!' }],\n          },\n        ]"}],attrs:{"type":"password","placeholder":"请输入旧密码"}})],1),_c('a-form-item',{attrs:{"label":"新密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'newPassword',
          {
            rules: [{ required: true, message: '新密码不能为空!' }],
          },
        ]),expression:"[\n          'newPassword',\n          {\n            rules: [{ required: true, message: '新密码不能为空!' }],\n          },\n        ]"}],attrs:{"type":"password","placeholder":"请输入新密码"}})],1),_c('a-form-item',{attrs:{"label":"确认密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'newPassword2',
          {
            rules: [
              { required: true, message: '确认密码不能为空!' },
              { validator: this.handlePasswordCheck },
            ],
          },
        ]),expression:"[\n          'newPassword2',\n          {\n            rules: [\n              { required: true, message: '确认密码不能为空!' },\n              { validator: this.handlePasswordCheck },\n            ],\n          },\n        ]"}],attrs:{"type":"password","placeholder":"请输入确认密码"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }