/*
 * @Author: zhangjingqing
 * @Date: 2022-02-14 14:48:46
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-04-17 23:01:37
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\api\position.js
 */
import { getAction, postAction } from "./http/manage";

const URL = process.env.VUE_APP_API_PREFIX;
// 查询岗位
const querySystemPositionList = (params) =>
  getAction(URL + "/position/querySystemPositionList", params);
// 创建岗位信息
const createSystemPosition = (params) =>
  postAction(URL + "/position/createSystemPosition", params);
// 编辑人员
const updateSystemPosition = (params) =>
  postAction(URL + "/position/updateSystemPosition", params);
// 删除岗位信息
const deleteSystemPosition = (params) =>
  postAction(URL + "/position/deleteSystemPosition?id=" + params.id);

export {
  querySystemPositionList,
  createSystemPosition,
  updateSystemPosition,
  deleteSystemPosition,
};
