<!--
 * @Author: zhangjingqing
 * @Date: 2022-02-14 13:56:46
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-16 21:53:59
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\userManage\user\components\UserModal.vue
-->
<template>
  <a-drawer
    :title="title"
    placement="right"
    :width="600"
    :closable="false"
    :visible="visible"
    @close="handleClose"
  >
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
      <a-form-item label="姓名">
        <a-input
          v-decorator="[
            'realname',
            { rules: [{ required: true, message: '姓名不能为空!' }] },
          ]"
          placeholder="请输入姓名"
          :disabled="status == 1"
        />
      </a-form-item>
      <a-form-item label="账号">
        <a-input
          v-decorator="[
            'email',
            { rules: [{ required: true, message: '邮箱不能为空!' }] },
          ]"
          placeholder="请输入邮箱"
          :disabled="status == 1"
        />
      </a-form-item>
      <a-form-item label="密码" v-if="status == 2">
        <a-input-password
          v-decorator="[
            'password',
            { rules: [{ required: true, message: '密码不能为空!' }] },
          ]"
          placeholder="请输入密码"
          :disabled="status == 1"
        />
      </a-form-item>
      <a-form-item label="手机号">
        <a-input
          v-decorator="[
            'mobile',
            { rules: [{ required: true, message: '手机号不能为空!' }] },
          ]"
          placeholder="请输入手机号"
          :disabled="status == 1"
        />
      </a-form-item>
      <a-form-item label="岗位">
        <a-tree-select
          :blockNode="true"
          :load-data="onLoadData"
          :tree-data="treeData"
          :replaceFields="{
            children: 'children',
            title: 'name',
            key: 'id',
            value: 'id',
          }"
          :loadedKeys="loadedKeys"
          @load="onLoad"
          v-decorator="[
            'positionId',
            { rules: [{ required: true, message: '岗位不能为空!' }] },
          ]"
          placeholder="请选择岗位"
          :disabled="status == 1"
        >
        </a-tree-select>
      </a-form-item>
      <a-form-item label="角色">
        <a-select
          v-decorator="[
            'agentRole',
            { rules: [{ required: true, message: '角色不能为空!' }] },
          ]"
          placeholder="请选择角色"
          :disabled="status == 1"
        >
          <a-select-option
            v-for="item in roleList"
            :key="item.id"
            :value="item.id.toString()"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="用户类型">
        <a-select
          v-decorator="[
            'type',
            { rules: [{ required: true, message: '用户类型不能为空!' }] },
          ]"
          placeholder="请选择类型"
          :disabled="status == 1"
        >
          <a-select-option :value="1"> 超级管理员 </a-select-option>
          <a-select-option :value="2"> 管理员 </a-select-option>
          <a-select-option :value="3"> 普通 </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <div
      :style="{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'right',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px',
      }"
    >
      <a-button style="margin-right: 8px" @click="handleClose">取消</a-button>
      <a-button type="primary" @click="handleSave">确定</a-button>
    </div>
  </a-drawer>
</template>
<script>
import pick from "lodash.pick";
import { createSystemUser, updateSystemUser } from "@/api/user";
import { querySystemRoleList } from "@/api/role";
import { querySystemPositionList } from "@/api/position";
export default {
  name: "userModal",
  data() {
    return {
      status: 1,
      visible: false,
      record: {},
      form: this.$form.createForm(this, { name: "form" }),
      confirmLoading: false,
      loadedKeys: [],
      treeData: [],
      roleList: [],
      positionList: [],
    };
  },
  methods: {
    async loadData() {
      this.loading = true;
      let res = await querySystemPositionList({ parentId: "" });
      if (res.status == 200) {
        this.treeData = [];
        let data = res.data[0];
        this.treeData.push(data);
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
      this.loading = false;
    },
    onLoadData(treeNode) {
      return new Promise((resolve) => {
        if (treeNode.dataRef.children) {
          resolve();
          return;
        }
        let id = treeNode.dataRef.id;
        this.loading = true;
        querySystemPositionList({ parentId: id }).then((res) => {
          if (res.status == 200) {
            let data = res.data;
            let children = [];
            data.forEach((item) => {
              children.push(item);
            });
            treeNode.dataRef.children = children;
            this.treeData = [...this.treeData];
            resolve();
          } else {
            this.$notification.error({
              message: "系统提示",
              description: res.msg || res.message,
              duration: 4,
            });
          }
          this.loading = false;
        });
      });
    },
    onLoad(loadedKeys) {
      this.loadedKeys = loadedKeys;
    },
    async queryRoleList() {
      this.loading = true;
      let res = await querySystemRoleList({
        page: 1,
        pageSize: 100,
      });
      if (res.status == 200) {
        this.roleList = res.data.rows;
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
      this.loading = false;
    },
    async show(status, record) {
      await this.loadData();
      await this.queryRoleList();
      this.status = status;
      this.visible = true;
      if (record) {
        let that = this;
        that.record = record;
        that.$nextTick(() => {
          that.form.setFieldsValue(
            pick(
              record,
              "realname",
              "email",
              "password",
              "mobile",
              "positionId",
              "agentRole",
              "type"
            )
          );
        });
      }
    },
    handleSave() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let res;
          if (this.status == 2) {
            res = await createSystemUser(values);
          } else {
            res = await updateSystemUser(Object.assign(this.record, values));
          }
          if (res.status == 200) {
            this.visible = false;
            this.$message.success(this.status == 2 ? "创建成功" : "修改成功");
            this.$emit("reload");
          } else {
            this.$notification.error({
              message: "系统提示",
              description: res.msg || res.message,
              duration: 4,
            });
          }
        }
      });
    },
    handleClose() {
      this.form.resetFields();
      this.visible = false;
    },
  },
  computed: {
    title() {
      let title;
      switch (this.status) {
        case 1:
          title = "查看";
          break;
        case 2:
          title = "添加用户";
          break;
        case 3:
          title = "编辑";
          break;
      }
      return title;
    },
  },
};
</script>
<style lang="less" scoped></style>
