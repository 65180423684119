/*
 * @Author: zhangjingqing
 * @Date: 2022-02-14 14:48:40
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-03 22:51:58
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\api\user.js
 */
import { getAction, postAction } from "./http/manage";
const URL = process.env.VUE_APP_API_PREFIX;
// 查询人员
const querySystemUserList = (params) =>
  getAction(URL + "/user/querySystemUserList", params);
// 创建人员
const createSystemUser = (params) =>
  postAction(URL + "/user/createSystemUser", params);
// 编辑人员
const updateSystemUser = (params) =>
  postAction(URL + "/user/updateSystemUser", params);
// 删除人员信息
const deleteSystemUser = (params) =>
  postAction(URL + "/user/deleteSystemUser?id=" + params.id);
// 更改人员状态 1-启用 2-停用
const updateStatus = (params) =>
  postAction(
    URL + "/user/updateStatus?id=" + params.id + "&status=" + params.status
  );
// 修改密码
const updatePassword = (params) =>
  postAction(
    URL +
      "/passport/updatePassword?email=" +
      params.email +
      "&oldPassword=" +
      params.oldPassword +
      "&newPassword=" +
      params.newPassword
  );

export {
  querySystemUserList,
  createSystemUser,
  updateSystemUser,
  deleteSystemUser,
  updateStatus,
  updatePassword,
};
